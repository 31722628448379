import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import {
  Button,
  Container,
  Form,
  Modal,
  Pagination,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ReportAction, ReportList } from "../Redux/Actions/AdminActions";
import SearchBar from "../Common/Searchbar";
import PaginationComponent from "../Components/Layout/Pagination";
import { all } from "axios";
import { toast } from "react-toastify";

export default function Report() {
  const { alldata } = useSelector((state) => state.reports);

  console.log(alldata);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [flag, setflag] = useState(true);
  const [show, setshow] = useState(false);
  const [ID, setID] = useState("");
  const [Type, settype] = useState("");

  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [searchValue, setSearchValue] = useState();

  let location = useLocation();
  const pageNo = new URLSearchParams(location?.search).get("page");

  console.log("alldataalldataalldata", alldata);

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    navigate(`/report?page=${pageNumber}`);
  };

  useEffect(() => {
    const searchData = {
      page: pageNo,
      limit: limit,
      search: searchValue,
    };
    dispatch(ReportList(searchData));
  }, [pageNo, limit, searchValue, flag, dispatch]);

  const pageNoValid = Number(pageNo) || 1;
  const limitValid = Number(limit) || 10;
  const totalCountValid = Number(alldata?.data?.totalCount) || 0;

  const handleAction = () => {
    let payload = { reportId: ID, type: Number(Type) };
    dispatch(ReportAction(payload))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status == 200) {
          toast?.success(res?.payload?.data?.message);
          setshow(false);
          setflag(!flag);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div>
              <h2 className="report-heading"> Report</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <SearchBar setSearchValue={setSearchValue} />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn table-report">
                <thead>
                  <tr>
                    <th>S.NO.</th>
                    <th>REASON</th>
                    <th>DESCRIPTION</th>
                    <th>REPORT BY</th>
                    <th>STATUS</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {alldata?.data?.reports?.map((res, index) => {
                    const dynamicIndex = (pageNo - 1) * limit + (index + 1);

                    return (
                      <tr key={index}>
                        <td>
                          <p>{dynamicIndex}</p>
                        </td>
                        <td>
                          <Link to={`/report-view/${res?._id}`}>
                            <p>{res?.reason || "N/A"}</p>
                          </Link>
                        </td>
                        <td>
                          <p>{res?.desc || "N/A"}</p>
                        </td>
                        <td>
                          <p>{res?.reportBy?.name || "N/A"}</p>
                        </td>
                        <td>
                          <div
                            className={`${
                              res?.status === 0 ? "active" : "delete"
                            }`}
                          >
                            <p>
                              {res?.status == 0 ? "Open" : "Closed" || "N/A"}
                            </p>
                          </div>
                        </td>
                        <td>
                          {/* <div className="delete">
                        <p>Delete</p>
                      </div> */}

                          <td>
                            <div className="report-select-box">
                            {res?.status == 0 ? (
                              <Form.Select
                                aria-label="Default select example"
                                value={res?.status}
                                onChange={(e) => {
                                  setshow(true);
                                  setID(res?._id);
                                  settype(e.target.value)
                                }}
                              >
                                <option
                                  value="0"
                                  disabled={res?.status == 0 ? true : false}
                                >
                                  Open
                                </option>
                                <option
                                  value="1"
                                  disabled={res?.status == 1 ? true : false}
                                >
                                  Close
                                </option>
                              </Form.Select>
                            ) : (
                              <>
                                <p className="form-control">Closed</p>
                              </>
                            )}
                            </div>
                          </td>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    Showing {1 + (pageNoValid - 1) * limitValid} -{" "}
                    {Math.min(pageNoValid * limitValid, totalCountValid)} of{" "}
                    {totalCountValid} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponent
                    currentPage={pageNo}
                    totalCount={alldata?.data?.totalCount}
                    limit={alldata?.data?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to close this report?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleAction}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
