import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Layout from "../Components/Layout/Layout";
import { Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { addServices } from "../Redux/Actions/AdminActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { type } from "@testing-library/user-event/dist/type";

const validateForm = (values) => {
  const errors = {};

  if (!values.serviceName) {
    errors.serviceName = "Required";
  }

  if (!values.price) {
    errors.price = "Required";
  } else if (isNaN(values.price)) {
    errors.price = "Price must be a valid number";
  }

  if (values.type == 0 || values.type == 1) {
    if (!values.pricePerMile) {
      errors.pricePerMile = "Required";
    } else if (isNaN(values.pricePerMile)) {
      errors.pricePerMile = "Price must be a valid number";
    }
  }

  if (!values.service_image) {
    errors.service_image = "Image is required";
  }

  if (!values.type) {
    errors.type = "Required";
  }

  return errors;
};

export default function AddCategories() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    serviceName: "",
    price: "",
    pricePerMile: "",
    service_image: null,
    type: "",
  };

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("serviceName", values.serviceName);
    formData.append("price", values.price);
    formData.append("pricePerMile", values.pricePerMile);
    formData.append("type", values.type);
    if (values.service_image) {
      formData.append("service_image", values.service_image);
    }
    dispatch(addServices(formData)).then((res) => {
      console.log(res);
      if (res?.payload?.data?.status == 200) {
        toast.success(res?.payload?.data?.message);
        navigate("/service-category");
      }
    });
  };

  return (
    <Layout>
      <Container fluid>
        <div className="filters mt-4">
          <div className="inner-filter-field">
            <div>
              <h2 className="report-heading">Add Categories</h2>
            </div>

            <div className="delete-post-form">
              <Formik
                initialValues={initialValues}
                validate={validateForm}
                onSubmit={(val) => handleSubmit(val)}
              >
                {({ values, handleChange, handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row className="">
                      <Col>
                        <div className="profile-disp post-view-margin-top">
                          <div className="form-set input-profile">
                            <Form.Group
                              className="mb-3 input-field-width "
                              controlId="postViewFormName"
                            >
                              <Form.Label>Select Category</Form.Label>
                              <Form.Select
                                id="category"
                                type="text"
                                placeholder="Selet Type"
                                className="select-option"
                                name="type"
                                value={values?.type}
                                onChange={handleChange}
                              >
                                <option>Select Type</option>
                                <option
                                  value="0"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Towing services"
                                >
                                  Type 0
                                </option>
                                <option
                                  value="1"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Fuel delivery services"
                                >
                                  Type 1
                                </option>
                                <option
                                  value="2"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Quick services (e.g. Battery jump and Flat tyre services)"
                                >
                                  Type 2
                                </option>
                                <option
                                  value="3"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Minor repair services"
                                >
                                  {" "}
                                  Type 3
                                </option>
                              </Form.Select>

                              <ErrorMessage
                                name="type"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="postViewFormPrice"
                            >
                              <Form.Label>Category Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Name..."
                                name="serviceName"
                                value={values.serviceName}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="serviceName"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </div>

                          <div className="form-set input-profile">
                            {values?.type == "0" ? (
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="postViewFormPricePerMile"
                              >
                                <Form.Label>Price Per Mile</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Price per mile"
                                  name="pricePerMile"
                                  value={values.pricePerMile}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="pricePerMile"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            ) : values?.type == "1" ? (
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="postViewFormPricePerMile"
                              >
                                <Form.Label>Price Per Letter</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Price per mile"
                                  name="pricePerMile"
                                  value={values.pricePerMile}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="pricePerMile"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            ) : values?.type == "2" ? null : null}

                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="postViewFormPricePerMile"
                            >
                              <Form.Label> Base Price</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=" Base price"
                                name="price"
                                value={values.price}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="price"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div className="profile-disp post-view-margin-top">
                          <div className="form-set input-profile">
                            <div className="up-load-image">
                              <div className="add-image-icon">
                                <p>Upload Image</p>
                                {values.imagePreview && (
                                  <div className="mt-3">
                                    <img
                                      src={values.imagePreview}
                                      alt="Image Preview"
                                      style={{
                                        maxWidth: "100%",
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <input
                                type="file"
                                name="service_image"
                                onChange={(event) => {
                                  const file = event.currentTarget.files[0];
                                  setFieldValue("service_image", file);

                                  // Preview the image
                                  if (file) {
                                    setFieldValue("service_image", file);

                                    // Preview the image
                                    const reader = new FileReader();
                                    reader.onloadend = () => {
                                      setFieldValue(
                                        "imagePreview",
                                        reader.result
                                      );
                                    };

                                    // Only read the file if it's valid
                                    if (file instanceof Blob) {
                                      reader.readAsDataURL(file);
                                    } else {
                                      console.error(
                                        "Selected file is not a valid Blob"
                                      );
                                    }
                                  }
                                }}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <ErrorMessage
                            name="service_image"
                            component="div"
                            className="text-danger"
                          />

                          <div className="create-btn mt-4 text-left-side">
                            <Button type="submit">Create</Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
