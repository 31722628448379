import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import CommonDashBoard from "../Common/CommonDashBoard";
import {
  DeleteUser,
  DowanloadUserxlsv,
  maangeuseraction,
  usermanagement,
} from "../Redux/Actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import SearchBar from "../Common/Searchbar";
import PaginationComponet from "../Components/Layout/Pagination";
import placeholder from "../Assets/Images/Road-assistance-placehodler.png";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { toast } from "react-toastify";

export default function User() {
  const { alldata, loading, error, param } = useSelector(
    (state) => state.usermgt
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);

  const [flag, setflag] = useState(true);
  const [userid, setUserid] = useState("");
 
  const [activePopoverId, setActivePopoverId] = useState(null);
  const [searchValue, setSearchValue] = useState("");


  let location = useLocation()
  const pageNo = new URLSearchParams(location?.search).get("page");

console.log("alldataalldataalldata",alldata)

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    navigate(`/user?page=${pageNumber}`)
    // dispatch(
    //   usermanagement({
    //     page: pageNumber,
    //     limit: limit,
    //   })
    // );
  };


  
  useEffect(() => {
    const searchData = {
      page: pageNo,
      limit: limit,
      search: searchValue,
    };
    dispatch(usermanagement(searchData));
  }, [pageNo, limit, searchValue, flag, dispatch]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadUserxlsv());
      console.log(response);

      const allData = response?.payload?.data?.data?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const handleDeleteUSer = () => {
    dispatch(DeleteUser({ id: userid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  const ActiveOrDeactivate = (userid, status) => {
    dispatch(maangeuseraction({ userId: userid, type: status }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setActivePopoverId(null);
          setflag(!flag);
        }
      })
      .catch((error) => {
        console.log(error, "Error occurred");
      });
  };

  const handleTogglePopover = (id) => {
    setActivePopoverId((prevId) => (prevId === id ? null : id));
  };

  const popover = (id) => {
    return (
      <Popover id="popover-basic" style={{ cursor: "pointer" }}>
        <Popover.Body>
          <p onClick={() => ActiveOrDeactivate(id, 1)}>Active</p>
          <p onClick={() => ActiveOrDeactivate(id, 0)}>Suspend</p>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <Layout>
      <Container fluid>
        {/* <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap">
                <Row>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Session</p>
                          <h2>
                            21,459 <span>(+29%)</span>
                          </h2>
                          <span className="total-users">Total Users</span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/session-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Service Providers </p>
                          <h2>
                            4,567 <span> (+18%) </span>
                          </h2>
                          <span className="total-users">
                            Last week analytics
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/padi-user.svg").default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Active Users</p>
                          <h2>
                            19,860 <span>(-14%)</span>
                          </h2>
                          <span className="total-users">
                            Last week analytics
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/active-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Pending Service providers</p>
                          <h2>
                            237<span>(+42%)</span>
                          </h2>
                          <span className="total-users">
                            Last week analytics
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/pending-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div> */}

        <CommonDashBoard />
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <SearchBar setSearchValue={setSearchValue} />
                    </Form.Group>
                  </Form>
                </div>
                <div className="export-box" onClick={fetchDataAndExportToExcel}>
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>
                      <div className="user-checkbox">
                        <div className="remember-check">
                        
                        </div>
                        User
                      </div>
                    </th>
                    <th>Role</th>
                    <th>Contact</th>
              
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {alldata?.data?.users?.map((res, index) => {
                    const dynamicIndex = (pageNo - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>{dynamicIndex}</td>
                        <td>
                          <div className="first-user">
                            <div className="remember-check"></div>
                            <div className="user-profile">
                              {res?.profileImage ? (
                                <img src={`${url}${res?.profileImage}`} />
                              ) : (
                                <img src={placeholder} />
                              )}

                              <div className="user-id">
                                {" "}
                                <p>{res?.name || "N/A"}</p>
                                <span>{res?.email || "N/A"}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>
                              {res?.role === 1 ? (
                                <span>
                                  <img
                                    src={
                                      require("../Assets/Images/crown.svg")
                                        .default
                                    }
                                  />
                                </span>
                              ) : (
                                <span>
                                  <img
                                    src={
                                      require("../Assets/Images/table-user.svg")
                                        .default
                                    }
                                  />
                                </span>
                              )}
                              {res?.role === 1 ? "Service Provider" : "User"}
                            </p>
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="cont-num">
                            {" "}
                            {res?.countryCode && res?.phoneNumber
                              ? `${res?.countryCode} ${res?.phoneNumber}`
                              : "N/A"}{" "}
                          </div>
                        </td>
                      
                        <td>
                          <div
                            className={`${
                              res?.isActive === 1 ? "active" : "pending"
                            }`}
                          >
                            <p>
                              {" "}
                              {res?.isActive === 1 ? "Active" : "Suspended"}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/delete-icon.svg")
                                  .default
                              }
                              onClick={() => {
                                setshowd(true);
                                setUserid(res?._id);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                            <Link
                              to={
                                res?.role === 0
                                  ? `/user-overview/${res?._id}?page=1`
                                  : `/service-verification-details/${res?._id}`
                              }
                            >
                              {" "}
                              <img
                                src={
                                  require("../Assets/Images/view-icon.svg")
                                    .default
                                }
                              />
                            </Link>
                            <OverlayTrigger
                              trigger="click"
                              show={activePopoverId === res._id}
                              placement="bottom"
                              overlay={popover(res?._id)}
                              onToggle={() => handleTogglePopover(res._id)}
                              rootClose
                            >
                              <img
                                src={
                                  require("../Assets/Images/options.svg")
                                    .default
                                }
                                alt="Options"
                                style={{ cursor: "pointer" }}
                              />
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    Showing {1 + (pageNo - 1) * limit} -
                    {Math.min(pageNo * limit, alldata?.data?.totalCount)}{" "}
                    of {alldata?.data?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={pageNo}
                    totalCount={alldata?.data?.totalCount}
                    limit={alldata?.data?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to Delete this user?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeleteUSer}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
