import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminApi } from "../../Services/AdminApi";

// ===== Get Admin Profile ==========

export const getAdminprofile = createAsyncThunk(
  "getAdminprofile",
  async (details) => {
    const { data } = await AdminApi.get(`/getAdminById`, details);
    return data;
  }
);

// ====== Edit admin profile ==========

export const Editadminprofile = createAsyncThunk(
  "Editadminprofile",
  async (details) => {
    const data = await AdminApi.put(`/editAdminProfile`, details);
    console.log(data);
    return data;
  }
);

// ========= get user list ============

export const usermanagement = createAsyncThunk(
  "usermanagement",
  async (details) => {
    let url = `/userManagement?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }
    if (details.role) {
      url += `&role=${details.role}`;
    }
    const data = await AdminApi.get(url);
    return data;
  }
);

// =======  Dowanload Users List ============

export const DowanloadUserxlsv = createAsyncThunk(
  "DowanloadUserxlsv",
  async () => {
    const data = await AdminApi.get(`/userManagement`);
    console.log(data);
    return data;
  }
);

// =======  Dowanload service provider List ============

export const Dowanloadserviceprovider = createAsyncThunk(
  "Dowanloadserviceprovider",
  async (details) => {
    let url = `/userManagement?page=${details?.page}`;
    if (details.role) {
      url += `&role=${details.role}`;
    }
    const data = await AdminApi.get(url);
    console.log(data);
    return data;
  }
);

// ======== Delete User account api =============

export const DeleteUser = createAsyncThunk("DeleteUser", async (details) => {
  const data = await AdminApi.delete(`/deleteUser/${details?.id}`);
  return data;
});

// ======== magage User account api =============

export const maangeuseraction = createAsyncThunk(
  "maangeuseraction",
  async (details) => {
    const data = await AdminApi.post(`/banUnbanUser`, details);
    return data;
  }
);

// ======== verify unverify serviceprovider api =============

export const verifyServiceprovider = createAsyncThunk(
  "verifyServiceprovider",
  async (details) => {
    const data = await AdminApi.post(`/verifyUnverifyUser`, details);
    return data;
  }
);

// ========= get user profile by id ==========

export const getuserprofile = createAsyncThunk(
  "getuserprofile",
  async (details) => {
    const data = await AdminApi.get(`/getUserById/${details?.id}`);
    console.log(data);
    return data;
  }
);

//  get dashboard api

export const getdashboard = createAsyncThunk(
  "getdashboard",
  async (details) => {
    const data = await AdminApi.get(`/dashboard`);
    console.log(data);
    return data;
  }
);

// get Services

export const ServicesManagement = createAsyncThunk(
  "ServicesManagement",
  async (details) => {
    let url = `/getServices?page=${details?.page}&limit=${details?.limit}`;
    if (details.search) {
      url += `&search=${details.search}`;
    }
    const data = await AdminApi.get(url);
    console.log(data, "datatatatata");
    return data;
  }
);

// dowanload services

export const DowanloadServices = createAsyncThunk(
  "DowanloadServices",
  async () => {
    const data = await AdminApi.get(`/getServices`);
    console.log(data);
    return data;
  }
);

// add Services Category

export const addServices = createAsyncThunk("addServices", async (details) => {
  const data = await AdminApi.put(`/addService`, details);
  return data;
});

// Delete Service

export const DeleteService = createAsyncThunk(
  "DeleteService",
  async (details) => {
    const data = await AdminApi.delete(`/deleteService/${details?.id}`);
    return data;
  }
);

// Active / Inactive Service category

export const activeDeactiveService = createAsyncThunk(
  "activeDeactiveService",
  async (details) => {
    const data = await AdminApi.put(`/addService`, details);
    return data;
  }
);

// Edit Service Category

export const Editservicecategory = createAsyncThunk(
  "Editservicecategory",
  async (details) => {
    const data = await AdminApi.put(`/addService`, details);
    return data;
  }
);

// get service category detail

export const getService = createAsyncThunk("getService", async (details) => {
  const data = await AdminApi.get(`/getServiceId/${details?.id}`);
  return data;
});

// Get All Notification

export const NotificationListing = createAsyncThunk(
  "NotificationListing",
  async (details) => {
    let url = `/getAllNotifications?page=${details?.page}&limit=${details?.limit}`;
    if (details.search) {
      url += `&search=${details.search}`;
    }
    const data = await AdminApi.get(url);
    return data;
  }
);

// Add Notification

export const Addnotification = createAsyncThunk(
  "Addnotification",
  async (details) => {
    const data = await AdminApi.post(`/addNotification`, details);
    return data;
  }
);

// Delete Notification

export const DeleteNotification = createAsyncThunk(
  "DeleteUser",
  async (details) => {
    const data = await AdminApi.delete(
      `/deleteNotification?notificationId=${details?.id}`
    );
    return data;
  }
);

// Get Support List

export const SupportList = createAsyncThunk("SupportList", async (details) => {
  let url = `/getContactList?page=${details?.page}&limit=${details?.limit}`;
  if (details.search) {
    url += `&search=${details.search}`;
  }
  const data = await AdminApi.get(url);
  return data;
});

// Dowanload Support List

export const dowanloadSupportList = createAsyncThunk(
  "dowanloadSupportList",
  async () => {
    const data = await AdminApi.get(`/getContactList`);
    return data;
  }
);

// Close Support

export const CloseSupport = createAsyncThunk(
  "CloseSupport",
  async (details) => {
    const data = await AdminApi.put(`/closeTicket`, details);
    return data;
  }
);

// get all activities
export const getActivities = createAsyncThunk(
  "getActivities",
  async (details) => {
    let url = `/getRecentActivity?page=${details?.page}&limit=${details?.limit}&userId=${details?.userId}`;
    const data = await AdminApi.get(url);
    return data;
  }
);


// All Reports

export const ReportList = createAsyncThunk("ReportList", async (details) => {
  let url = `/getReports?page=${details?.page}&limit=${details?.limit}`;
  if (details.search) {
    url += `&search=${details.search}`;
  }
  const data = await AdminApi.get(url);
  return data;
});

// get report detail by id  

export const getreportdetail = createAsyncThunk(
  "getreportdetail",
  async (details) => {
    const data = await AdminApi.get(`/getReportById/${details?.id}`);
    return data;
  }
);

// Handle report action open/close
export const ReportAction = createAsyncThunk(
  "ReportAction",
  async (details) => {
    const data = await AdminApi.put(`/changeReportStatus`, details);
    return data;
  }
);


