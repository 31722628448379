import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Layout from "../Components/Layout/Layout";
import { Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  Editservicecategory,
  addServices,
  getService,
} from "../Redux/Actions/AdminActions";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const validateForm = (values) => {
  const errors = {};

  if (!values.service_name) {
    errors.service_name = "Required";
  }

  if (!values.price) {
    errors.price = "Required";
  } else if (isNaN(values.price)) {
    errors.price = "Price must be a valid number";
  }

  if (values.type == 0 || values.type == 1) {
    if (!values.pricePerMile) {
      errors.pricePerMile = "Required";
    } else if (values.pricePerMile <= 0) {
      errors.pricePerMile = "Price per mile must be positive";
    } else if (!Number.isInteger(values.pricePerMile)) {
      errors.pricePerMile = "Price per mile must be an integer";
    }
  }

  if (!values.service_image) {
    errors.service_image = "Image is required";
  }

  if (!values.type) {
    errors.type = "Required";
  }

  return errors;
};

export default function EditService() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [services, setServices] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const initialValues = {
    service_name: services?.service_name ?? "",
    price: services?.price ?? "",
    pricePerMile: services?.pricePerMile ?? "",
    service_image: services?.service_image ?? null,
    type: services?.type ?? "",
  };

  useEffect(() => {
    dispatch(getService({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setServices(res?.payload?.data?.data?.service);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("serviceName", values.service_name);
    formData.append("price", values.price);
    values.pricePerMile && formData.append("pricePerMile", values.pricePerMile);
    formData.append("type", values.type);
    formData.append("serviceId", id);
    if (values.service_image) {
      formData.append("service_image", values.service_image);
    }
    dispatch(Editservicecategory(formData)).then((res) => {
      console.log(res);
      if (res?.payload?.data?.status == 200) {
        toast.success(res?.payload?.data?.message);
        navigate("/service-category");
      }
    });
  };
  console.log("servicesservicesservicesservices", services);
  return (
    <Layout>
      <Container fluid>
        <div className="filters mt-4">
          <div className="inner-filter-field">
            <div>
              <h2 className="report-heading">Edit Categories</h2>
            </div>

            <div className="delete-post-form">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validate={validateForm}
                onSubmit={(val) => handleSubmit(val)}
              >
                {({ values, handleChange, handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row className="">
                      <Col>
                        <div className="profile-disp post-view-margin-top">
                          <div className="form-set input-profile">
                            <Form.Group
                              className="mb-3 input-field-width "
                              controlId="postViewFormName"
                            >
                              <Form.Label>Select Category</Form.Label>
                              <Form.Select
                                id="category"
                                type="text"
                                placeholder="Selet Type"
                                className="select-option"
                                name="type"
                                value={values?.type}
                                onChange={handleChange}
                              >
                                <option>Select Type</option>
                                <option
                                  value="0"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Car towing services"
                                >
                                  Type 0
                                </option>
                                <option
                                  value="1"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Fuel delivery services"
                                >
                                  Type 1
                                </option>
                                <option
                                  value="2"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Battery jump and Flat tyre services"
                                >
                                  Type 2
                                </option>
                                <option
                                  value="3"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Minor repair services"
                                >
                                  {" "}
                                  Type 3
                                </option>
                              </Form.Select>
                              <ErrorMessage
                                name="type"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="postViewFormPrice"
                            >
                              <Form.Label>Category Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Name..."
                                name="service_name"
                                value={values.service_name}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="service_name"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </div>

                          <div className="form-set input-profile">
                            {values?.type == "0" ? (
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="postViewFormPricePerMile"
                              >
                                <Form.Label>Price Per Mile</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Price per mile"
                                  name="pricePerMile"
                                  value={values.pricePerMile}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="pricePerMile"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            ) : values?.type == "1" ? (
                              <Form.Group
                                className="mb-3 input-field-width"
                                controlId="postViewFormPricePerMile"
                              >
                                <Form.Label>Price Per Letter</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Price per mile"
                                  name="pricePerMile"
                                  value={values.pricePerMile}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="pricePerMile"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            ) : values?.type == "2" ? null : null}

                            <Form.Group
                              className="mb-3 input-field-width"
                              controlId="postViewFormPrice"
                            >
                              <Form.Label>Base Price</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Base Price"
                                name="price"
                                value={values.price}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="price"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div className="profile-disp post-view-margin-top">
                          <div className="form-set input-profile">
                            <div className="up-load-image">
                              {imagePreview ? (
                                <>
                                  <div className="mt-3">
                                    <img
                                      src={imagePreview}
                                      alt="Image Preview"
                                      style={{
                                        maxWidth: "100%",
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  {values?.service_image ? (
                                    <div className="mt-3">
                                      <img
                                        src={`${url}${values.service_image}`}
                                        alt="Image Preview"
                                        style={{
                                          maxWidth: "100%",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="add-image-icon">
                                      <p>Upload Image</p>
                                      {values.service_image && (
                                        <div className="mt-3">
                                          <img
                                            src={values.service_image}
                                            alt="Image Preview"
                                            style={{
                                              maxWidth: "100%",
                                              height: "auto",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </>
                              )}

                              <input
                                type="file"
                                name="service_image"
                                accept="image/*"
                                onChange={(event) => {
                                  const file = event.currentTarget.files[0];
                                  setFieldValue("service_image", file);

                                  // Preview the image
                                  const reader = new FileReader();
                                  reader.onloadend = () => {
                                    setImagePreview(reader.result);
                                  };
                                  reader.readAsDataURL(file);
                                }}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <ErrorMessage
                            name="service_image"
                            component="div"
                            className="text-danger"
                          />

                          <div className="create-btn mt-4 text-left-side">
                            <Button type="submit">Update</Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
