import { createSlice } from "@reduxjs/toolkit";
import { ReportList } from "../Actions/AdminActions";

export const reportSlice = createSlice({
    name: "reportSlice",
    initialState: {
        alldata: "",
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(ReportList.pending, (state) => {
                state.loading = true; 
                state.error = null;  
            })
            .addCase(ReportList.fulfilled, (state, action) => {
                state.loading = false; 
                state.alldata = action.payload.data; 
            })
            .addCase(ReportList.rejected, (state, action) => {
                state.loading = false; 
                state.error = action.error.message; 
            });
    },
});

export default reportSlice.reducer;
