import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Layout from "../Components/Layout/Layout";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Addnotification } from "../Redux/Actions/AdminActions";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Please select a user type"),
  title: Yup.string().trim().required("Required"),
  desc: Yup.string().trim().required("Required"),
});

export default function CreateNotification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const InitialValues = {
    type: "",
    title: "",
    desc: "",
  };

  const handleSubmit = async (values) => {
    const payload = {
      title: values?.title,
      send_to: values?.type,
      desc: values?.desc,
    };
    try {
      const data = await dispatch(Addnotification(payload));
      console.log(data);
      if (data?.payload?.data?.status === 200) {
        toast.success(data?.payload?.data?.message);
        navigate("/notification");
      } else {
        toast.error(data?.payload?.data?.message);
      }
    } catch (error) {
      console.log(error, "error occur");
    }
  };

  return (
    <Layout>
      <Container fluid>
        <div className="filters mt-4">
          <div className="inner-filter-field">
            <div>
              <h2 className="report-heading"> Create Notification</h2>
            </div>
            <Formik
              initialValues={InitialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="delete-post-form">
                    <Row className="post-view create-notification">
                      <Col>
                        <div className="profile-disp post-view-margin-top">
                          <div className="form-set input-profile ">
                            <Form.Group
                              className="mb-3 input-field-width "
                              controlId="postViewFormName"
                            >
                              <Form.Label>Select User</Form.Label>
                              <Form.Select
                                id="category"
                                type="text"
                                placeholder="Search"
                                className="select-option"
                                name="type"
                                value={values?.type}
                                onChange={handleChange}
                              >
                                <option value="">Select</option>
                                <option value="2">All users</option>
                                <option value="0">Users</option>
                                <option value="1">Service provider</option>
                              </Form.Select>
                              <ErrorMessage
                                name="type"
                                component="div"
                                className="error-message"
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 input-field-width "
                              controlId="postViewFormTitle"
                            >
                              <Form.Label>Title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type here"
                                name="title"
                                value={values?.title}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="title"
                                component="div"
                                className="error-message"
                              />
                            </Form.Group>
                          </div>

                          <div className="form-set input-profile mt-2">
                            <Form.Group
                              className="mb-3 input-field-width post-view-input-width "
                              controlId="postViewFormDescription"
                            >
                              <Form.Label>Description</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={4}
                                placeholder="Type here"
                                className="description-textarea"
                                name="desc"
                                value={values?.desc}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="desc"
                                component="div"
                                className="error-message"
                              />
                            </Form.Group>
                          </div>
                          <div className="create-btn">
                            <Button type="submit">Create</Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
