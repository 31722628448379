import { Link, useNavigate } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import {
  Button,
  Container,
  Form,
  Modal,
  Pagination,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseSupport,
  SupportList,
  dowanloadSupportList,
} from "../Redux/Actions/AdminActions";
import { useCallback, useEffect, useState } from "react";
import SearchBar from "../Common/Searchbar";
import { all } from "axios";
import PaginationComponent from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import moment from "moment";

export default function Support() {
  const { alldata, loading, error, param } = useSelector(
    (state) => state.support
  );

  console.log(alldata);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [show, setshow] = useState(false);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [ID, setID] = useState("");
  const [flag, setflag] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      SupportList({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: searchValue,
    };
    dispatch(SupportList(searchData));
  }, [page, limit, searchValue, flag, dispatch]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(dowanloadSupportList());
      console.log(response);

      const allData = response?.payload?.data?.data?.contactList;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const handleAction = (id) => {
    dispatch(CloseSupport({ ticketId: ID }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status == 200) {
          toast?.success(res?.payload?.data?.message);
          setshow(false);
          setflag(!flag);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div>
              <h2 className="report-heading"> Support</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab support-search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <SearchBar setSearchValue={setSearchValue} />
                    </Form.Group>
                  </Form>
                </div>
                {/* <div className="create-date-range-box">
                  <p>
                    <span>
                      <img
                        src={
                          require("../Assets/Images/upload-purple.svg").default
                        }
                      />
                    </span>
                    Create Date Range
                  </p>
                </div> */}
                <div
                  className="download-box"
                  onClick={fetchDataAndExportToExcel}
                >
                  <p>
                    <span>
                      <img
                        src={
                          require("../Assets/Images/download-white.svg").default
                        }
                      />
                    </span>
                    Download
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn table-report">
                <thead>
                  <tr>
                    <th>S.NO.</th>
                    <th>DATE</th>
                    <th>SENDER NAME</th>
                    <th>EMAIL</th>
                    <th>MESSAGE</th>
                    <th>STATUS</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {alldata?.data?.contactList?.map((res, index) => {
                    const dynamicIndex = (page - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>
                          <p>{dynamicIndex}</p>
                        </td>
                        <td>
                          <p>
                            {moment(res?.createdAt).format("DD/MM/YYYY") ||
                              "N/A"}
                          </p>
                        </td>

                        <td>
                          <p>{res?.name || "N/A"}</p>
                        </td>
                        <td>
                          <p>{res?.email || "N/A"}</p>
                        </td>
                        <td>
                          <p>{res?.message || "N/A"}</p>
                        </td>
                        <td>
                          <div
                            className={`${
                              res?.status === 0 ? "active" : "delete"
                            }`}
                          >
                            <p>
                              {res?.status == 0 ? "Open" : "Closed" || "N/A"}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="Support-select-box">
                            {res?.status == 0 ? (
                              <Form.Select
                                aria-label="Default select example"
                                value={res?.status}
                                onChange={() => {
                                  setshow(true);
                                  setID(res?._id);
                                }}
                              >
                                <option
                                  value="0"
                                  disabled={res?.status == 0 ? true : false}
                                >
                                  Open
                                </option>
                                <option
                                  value="1"
                                  disabled={res?.status == 1 ? true : false}
                                >
                                  Close
                                </option>
                              </Form.Select>
                            ) : (
                              <>
                                <p className="form-control">Closed</p>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (page - 1) * limit} -
                    {Math.min(page * limit, alldata?.data?.totalCount)} of{" "}
                    {alldata?.data?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponent
                    currentPage={page}
                    totalCount={alldata?.data?.totalCount}
                    limit={alldata?.data?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Close this Ticket?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleAction}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
