import React, { useContext, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Button, Container, Placeholder } from "react-bootstrap";
import { getAdminprofile } from "../Redux/Actions/AdminActions";
import { useDispatch } from "react-redux";
import { ProfileContext } from "../Context/ProfileContext";
import placeholder from "../Assets/Images/Road-assistance-placehodler.png";
import { useNavigate } from "react-router-dom";


const TopHeader = () => {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const {profileData} = useContext(ProfileContext);
  const [admindetails, setAdmindetails] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    dispatch(getAdminprofile({ token: token }))
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.status === 200) {
          setAdmindetails(response?.payload?.data?.user);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, [profileData]);



    const handleLogout = () => {
      sessionStorage.clear();
      navigate('/')
    }

 


  return (
    <div>
      <Container fluid>
        <div className="dashboard-items mt-3">
        
          <div className="right-top-bar">
            <div className="left-side-bar">
        
            </div>
            <div className="right-side-bar">
            <div className="language">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="language-dropdown"
                  >
                    {/* <div className="language-icon">
                      <img
                        src={require("../Assets/Images/language.svg").default}
                        alt="theme icon"
                      />
                    </div> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="language-dropdown-menu">
                    <Dropdown.Item className="language-dropdown-menu-item">
                      <p>English</p>
                    </Dropdown.Item>
                    <Dropdown.Item className="language-dropdown-menu-item">
                      <p>French</p>
                    </Dropdown.Item>
                    <Dropdown.Item className="language-dropdown-menu-item">
                      <p>German</p>
                    </Dropdown.Item>
                    <Dropdown.Item className="language-dropdown-menu-item">
                      <p>Portuguese</p>
                    </Dropdown.Item>
                    <Dropdown.Item className="language-dropdown-menu-item">
                      <p>Arabic</p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="theme-change">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="language-dropdown"
                  >
                    {/* <div className="theme-change-icon">
                      <img
                        src={require("../Assets/Images/moon-stars.svg").default}
                        alt="theme icon"
                      />
                    </div> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="language-dropdown-menu">
                    <Dropdown.Item className="language-dropdown-menu-item">
                      <div>
                        <i>
                          <svg
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M12 3V4M12 20V21M4 12H3M6.31412 6.31412L5.5 5.5M17.6859 6.31412L18.5 5.5M6.31412 17.69L5.5 18.5001M17.6859 17.69L18.5 18.5001M21 12H20M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
                                stroke="#2F2B3DE5"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </g>
                          </svg>
                        </i>
                        <p>Light</p>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item className="language-dropdown-menu-item">
                      <div>
                        <i>
                          <svg
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 2.99994C12.132 2.99994 12.263 2.99994 12.393 2.99994C9.74214 5.46311 9.22802 9.46779 11.1707 12.5207C13.1134 15.5736 16.9589 16.804 20.313 15.4459C18.5803 19.6151 14.0268 21.8552 9.66662 20.6836C5.30643 19.512 2.48907 15.2912 3.07943 10.8151C3.66978 6.33903 7.48513 2.99303 12 2.99194V2.99994Z"
                              stroke="#2F2B3DE5"
                              stroke-opacity="0.9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M17 4C17 5.10457 17.8954 6 19 6C17.8954 6 17 6.89543 17 8C17 6.89543 16.1046 6 15 6C16.1046 6 17 5.10457 17 4"
                              stroke="#2F2B3DE5"
                              stroke-opacity="0.9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19 10.25C18.5858 10.25 18.25 10.5858 18.25 11C18.25 11.4142 18.5858 11.75 19 11.75V10.25ZM21 11.75C21.4142 11.75 21.75 11.4142 21.75 11C21.75 10.5858 21.4142 10.25 21 10.25V11.75ZM20.75 10C20.75 9.58579 20.4142 9.25 20 9.25C19.5858 9.25 19.25 9.58579 19.25 10H20.75ZM19.25 12C19.25 12.4142 19.5858 12.75 20 12.75C20.4142 12.75 20.75 12.4142 20.75 12H19.25ZM19 11.75H21V10.25H19V11.75ZM19.25 10V12H20.75V10H19.25Z"
                              fill="#2F2B3DE5"
                              fill-opacity="0.9"
                            />
                          </svg>
                        </i>
                        <p>Dark</p>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item className="language-dropdown-menu-item">
                      <div>
                        <i>
                          <svg
                            width="16px"
                            height="16px"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="2.75"
                              y="3.66675"
                              width="16.5"
                              height="11"
                              rx="1"
                              stroke="#2F2B3DE5"
                              stroke-opacity="0.9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.4165 18.3333H15.5832"
                              stroke="#2F2B3DE5"
                              stroke-opacity="0.9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.24984 14.6667V18.3334"
                              stroke="#2F2B3DE5"
                              stroke-opacity="0.9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M13.7498 14.6667V18.3334"
                              stroke="#2F2B3DE5"
                              stroke-opacity="0.9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.24984 10.9999V7.33325"
                              stroke="#2F2B3DE5"
                              stroke-opacity="0.9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.9998 10.9999V10.0833"
                              stroke="#2F2B3DE5"
                              stroke-opacity="0.9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M13.7498 11.0001V9.16675"
                              stroke="#2F2B3DE5"
                              stroke-opacity="0.9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.9998 10.9999V10.0833"
                              stroke="#2F2B3DE5"
                              stroke-opacity="0.9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </i>
                        <p>System</p>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="notify-icon">
                <div className="notification-icon">
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C16.3402 6.10655 17.8786 8.41425 18 11V14C18.1526 15.2608 18.8949 16.3742 20 17H4C5.10511 16.3742 5.84739 15.2608 6 14V11C6.12137 8.41425 7.65983 6.10655 10 5"
                      stroke="#2F2B3D"
                      stroke-opacity="0.9"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 17V18C9 19.6569 10.3431 21 12 21C13.6569 21 15 19.6569 15 18V17"
                      stroke="#2F2B3D"
                      stroke-opacity="0.9"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg> */}

                  <Dropdown >
                    <Dropdown.Toggle variant="success" id="dropdown-basic" >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C16.3402 6.10655 17.8786 8.41425 18 11V14C18.1526 15.2608 18.8949 16.3742 20 17H4C5.10511 16.3742 5.84739 15.2608 6 14V11C6.12137 8.41425 7.65983 6.10655 10 5"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9 17V18C9 19.6569 10.3431 21 12 21C13.6569 21 15 19.6569 15 18V17"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="notification-dropdown-menu">
                      <Dropdown.Item className="">
                        <div className="notify-head">
                          <p>Notifications</p>
                          <div className="">
                            <span>8 New</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M2.5 7.5L10 12.5L17.5 7.5L10 2.5L2.5 7.5"
                                stroke="#2F2B3D"
                                stroke-opacity="0.9"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M17.5 7.5V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V7.5"
                                stroke="#2F2B3D"
                                stroke-opacity="0.9"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M2.5 15.8334L7.5 10.8334"
                                stroke="#2F2B3D"
                                stroke-opacity="0.9"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.5 10.8334L17.5 15.8334"
                                stroke="#2F2B3D"
                                stroke-opacity="0.9"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider className="divider-dropdown" />
                      <Dropdown.Item className="notification-dropdown-menu-item">
                        <div className="drop-pro-view">
                          <div className="user-notfic-icon">
                            <img
                              src={require("../Assets/Images/avatar.png")}
                              alt="User Pic"
                            />
                          </div>
                          <div className="notification-box">
                            <h3>Congratulation Flora! 🎉</h3>
                            <p>Won the monthly best seller gold badge</p>
                            <span>Today</span>
                          </div>
                          <div className=" notification-circle">
                            <img
                              src={
                                require("../Assets/Images/circle-filled.svg")
                                  .default
                              }
                              alt="Circle"
                            />
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider className="divider-dropdown" />
                      <Dropdown.Item
                        href="#/action-3"
                        className="notification-dropdown-menu-item"
                      >
                        <div className="drop-pro-view">
                          <div className="user-notfic-icon">
                            <div className="user-notifiaction-bg">VU</div>
                          </div>
                          <div className="notification-box">
                            <h3>New user registered.</h3>
                            <p>Accepted your connection</p>
                            <span>Yesterday</span>
                          </div>
                          <div className=" notification-circle"></div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider className="divider-dropdown" />
                      <Dropdown.Item
                        href="#/action-2"
                        className="notification-dropdown-menu-item"
                      >
                        <div className="drop-pro-view">
                          <div className="user-notfic-icon">
                            <img
                              src={require("../Assets/Images/avatar1.png")}
                              alt="User Pic"
                            />
                          </div>
                          <div className="notification-box">
                            <h3>New message received 👋🏻</h3>
                            <p>You have new message from Natalie</p>
                            <span>11 Aug</span>
                          </div>
                          <div className=" notification-circle">
                            <img
                              src={
                                require("../Assets/Images/circle-grey.svg")
                                  .default
                              }
                              alt="Circle"
                            />
                            <img
                              src={
                                require("../Assets/Images/cross-grey.svg")
                                  .default
                              }
                              className="notification-cross-grey"
                              alt="Circle"
                            />
                          </div>
                        </div>
                      </Dropdown.Item>

                      <Dropdown.Divider className="divider-dropdown" />
                      <Dropdown.Item
                        href="#/action-3"
                        className="notification-dropdown-menu-item"
                      >
                        <div className="drop-pro-view">
                          <div className="user-notfic-icon">
                            <div className="user-notification-paypal-bg">
                              <img
                                src={
                                  require("../Assets/Images/paypal.svg").default
                                }
                                alt="Paypal"
                              />
                            </div>
                          </div>
                          <div className="notification-box">
                            <h3>Paypal</h3>
                            <p>AACME Inc. made new order $1,154</p>
                            <span>25 May</span>
                          </div>
                          <div className=" notification-circle"></div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider className="divider-dropdown" />
                      <Dropdown.Item
                        href="#/action-3"
                        className="notification-dropdown-menu-item"
                      >
                        <div className="drop-pro-view">
                          <div className="user-notfic-icon">
                            <img
                              src={require("../Assets/Images/avatar3.png")}
                              alt="User Pic"
                            />
                          </div>
                          <div className="notification-box">
                            <h3>Application has been approved 🚀</h3>
                            <p>
                              Your ABC project application has been approved.
                            </p>
                            <span>19 Mar</span>
                          </div>
                          <div className=" notification-circle">
                            <img
                              src={
                                require("../Assets/Images/circle-filled.svg")
                                  .default
                              }
                              alt="Circle"
                            />
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider className="divider-dropdown" />

                      <Dropdown.Item href="#" className="view-all-notifi-btn">
                        <Button>
                        View All Notifications
                        </Button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {/* <div className="notify-icon">
                <div className="notification-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C16.3402 6.10655 17.8786 8.41425 18 11V14C18.1526 15.2608 18.8949 16.3742 20 17H4C5.10511 16.3742 5.84739 15.2608 6 14V11C6.12137 8.41425 7.65983 6.10655 10 5"
                      stroke="#2F2B3D"
                      stroke-opacity="0.9"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 17V18C9 19.6569 10.3431 21 12 21C13.6569 21 15 19.6569 15 18V17"
                      stroke="#2F2B3D"
                      stroke-opacity="0.9"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C16.3402 6.10655 17.8786 8.41425 18 11V14C18.1526 15.2608 18.8949 16.3742 20 17H4C5.10511 16.3742 5.84739 15.2608 6 14V11C6.12137 8.41425 7.65983 6.10655 10 5"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9 17V18C9 19.6569 10.3431 21 12 21C13.6569 21 15 19.6569 15 18V17"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item className="">
                        <div className="notify-head">
                          <p>Notifications</p>
                          <div className="">
                            <span>8 New</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M2.5 7.5L10 12.5L17.5 7.5L10 2.5L2.5 7.5"
                                stroke="#2F2B3D"
                                stroke-opacity="0.9"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M17.5 7.5V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V7.5"
                                stroke="#2F2B3D"
                                stroke-opacity="0.9"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M2.5 15.8334L7.5 10.8334"
                                stroke="#2F2B3D"
                                stroke-opacity="0.9"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.5 10.8334L17.5 15.8334"
                                stroke="#2F2B3D"
                                stroke-opacity="0.9"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item className="">
                        <div className="drop-pro-view">
                          <div className="user-notfic-icon">
                            <img src={require("../Assets/Images/user.png")} />
                          </div>
                          <div className="notification-box">
                            <h3>Congratulation Flora! 🎉</h3>
                            <p>Won the monthly best seller gold badge</p>
                            <span>Today</span>
                          </div>
                          <div className="">
                            <span></span>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/action-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <ellipse
                            cx="10.9999"
                            cy="6.41667"
                            rx="3.66667"
                            ry="3.66667"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                        My Profile
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.46458 3.95725C9.85508 2.34758 12.1449 2.34758 12.5354 3.95725C12.6543 4.44772 13.0002 4.85194 13.4664 5.0451C13.9327 5.23826 14.4631 5.19713 14.894 4.93442C16.3084 4.07275 17.9282 5.69158 17.0665 7.10692C16.8042 7.53762 16.7631 8.06766 16.9561 8.5336C17.149 8.99954 17.5527 9.34542 18.0428 9.46458C19.6524 9.85508 19.6524 12.1449 18.0428 12.5354C17.5523 12.6543 17.1481 13.0002 16.9549 13.4664C16.7617 13.9327 16.8029 14.4631 17.0656 14.894C17.9272 16.3084 16.3084 17.9282 14.8931 17.0665C14.4624 16.8042 13.9323 16.7631 13.4664 16.9561C13.0005 17.149 12.6546 17.5527 12.5354 18.0428C12.1449 19.6524 9.85508 19.6524 9.46458 18.0428C9.34574 17.5523 8.9998 17.1481 8.53357 16.9549C8.06734 16.7617 7.53689 16.8029 7.106 17.0656C5.69158 17.9272 4.07183 16.3084 4.9335 14.8931C5.19584 14.4624 5.23687 13.9323 5.04393 13.4664C4.851 13.0005 4.44727 12.6546 3.95725 12.5354C2.34758 12.1449 2.34758 9.85508 3.95725 9.46458C4.44772 9.34574 4.85194 8.9998 5.0451 8.53357C5.23826 8.06734 5.19713 7.53689 4.93442 7.106C4.07275 5.69158 5.69158 4.07183 7.10692 4.9335C8.02358 5.49083 9.21158 4.99767 9.46458 3.95725Z"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <circle
                            cx="11"
                            cy="11"
                            r="2.75"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                        Settings
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/action-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            d="M7.33325 7.33337C7.33325 5.81459 8.76967 4.58337 10.5416 4.58337H11.4583C13.2302 4.58337 14.6666 5.81459 14.6666 7.33337C14.7359 8.55599 13.9885 9.67715 12.8333 10.0834C11.678 10.625 10.9306 12.1199 10.9999 13.75"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.0001 17.4166V17.4258"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        FAQ
                      </Dropdown.Item>
                      <Dropdown.Item href="/" className="logout-btn">
                        Logout
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                        >
                          <path
                            d="M3.41659 12.8333C3.26188 12.8333 3.1135 12.7718 3.00411 12.6624C2.89471 12.553 2.83325 12.4047 2.83325 12.25V1.74996C2.83325 1.59525 2.89471 1.44688 3.00411 1.33748C3.1135 1.22808 3.26188 1.16663 3.41659 1.16663H11.5833C11.738 1.16663 11.8863 1.22808 11.9957 1.33748C12.1051 1.44688 12.1666 1.59525 12.1666 1.74996V3.49996H10.9999V2.33329H3.99992V11.6666H10.9999V10.5H12.1666V12.25C12.1666 12.4047 12.1051 12.553 11.9957 12.6624C11.8863 12.7718 11.738 12.8333 11.5833 12.8333H3.41659ZM10.9999 9.33329V7.58329H6.91659V6.41663H10.9999V4.66663L13.9166 6.99996L10.9999 9.33329Z"
                            fill="white"
                          />
                        </svg>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div> */}
              <div className="user-img-top">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {admindetails?.profile_image ? (
                      <img src={`${url}${admindetails?.profile_image}`} />
                    ) : (
                      <img src={placeholder}/>
                    )}
                
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item className="">
                      <div className="drop-pro-view">
                      {admindetails?.profile_image ? (
                      <img src={`${url}${admindetails?.profile_image}`} />
                    ) : (
                      <img src={placeholder}/>
                    )}
                        <div className="admin-name">
                          <h3>{admindetails?.first_name && admindetails?.last_name  ? `${admindetails?.first_name} ${admindetails?.last_name}` : "N/A"}</h3>
                          <p>Admin</p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <div className="my-profile">
                      <Dropdown.Item href="my-profile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <ellipse
                            cx="10.9999"
                            cy="6.41667"
                            rx="3.66667"
                            ry="3.66667"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                        My Profile
                      </Dropdown.Item>
                    </div>

              
                    <Dropdown.Divider />
                   

                    <Dropdown.Item className="logout-btn" onClick={handleLogout}>
                      Logout
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M3.41659 12.8333C3.26188 12.8333 3.1135 12.7718 3.00411 12.6624C2.89471 12.553 2.83325 12.4047 2.83325 12.25V1.74996C2.83325 1.59525 2.89471 1.44688 3.00411 1.33748C3.1135 1.22808 3.26188 1.16663 3.41659 1.16663H11.5833C11.738 1.16663 11.8863 1.22808 11.9957 1.33748C12.1051 1.44688 12.1666 1.59525 12.1666 1.74996V3.49996H10.9999V2.33329H3.99992V11.6666H10.9999V10.5H12.1666V12.25C12.1666 12.4047 12.1051 12.553 11.9957 12.6624C11.8863 12.7718 11.738 12.8333 11.5833 12.8333H3.41659ZM10.9999 9.33329V7.58329H6.91659V6.41663H10.9999V4.66663L13.9166 6.99996L10.9999 9.33329Z"
                          fill="white"
                        />
                      </svg>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TopHeader;
