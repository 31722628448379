import { createSlice } from "@reduxjs/toolkit";
import { ServicesManagement } from "../Actions/AdminActions";

export const ServicesSlice = createSlice({
  name: "ServicesSlice",
  initialState: {
    alldata: "",
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    
 
    builder
      .addCase(ServicesManagement.pending, (state) => {

        state.loading = true;
        state.error = null;
      })
      .addCase(ServicesManagement.fulfilled, (state, action) => {
    
        console.log(state);
        state.loading = false;
        state.alldata = action.payload.data;
      })
      .addCase(ServicesManagement.rejected, (state, action) => {
  
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default ServicesSlice.reducer;
