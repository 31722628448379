import { configureStore } from '@reduxjs/toolkit'
import { usermanageSlice } from './Reducer/UsermanagementSlice';
import { dashboardSlice } from './Reducer/DashboardSlice';
import { ServicesSlice } from './Reducer/ServicesmanageSLice';
import { notificationSlice } from './Reducer/NotificationSlice';
import { supportSlice } from './Reducer/SupportSlice';
import { activitySlice } from './Reducer/ActivitySlice';
import { reportSlice } from './Reducer/ReportSlice';



export const Store = configureStore({
    reducer: {
        usermgt:usermanageSlice.reducer,
        dash:dashboardSlice.reducer,
        Services:ServicesSlice.reducer,
        noti:notificationSlice.reducer,
        support:supportSlice.reducer,
        activities:activitySlice.reducer,
        reports:reportSlice.reducer
    },
});