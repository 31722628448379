import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Layout from "../Components/Layout/Layout";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getreportdetail } from "../Redux/Actions/AdminActions";

export default function ReportView() {
  const [Reportdetails, setreportdetail] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();
  console.log(id);

  useEffect(() => {
    dispatch(getreportdetail({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setreportdetail(res?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Layout>
      <Container fluid>
        <div className="filters mt-4">
          <div className="inner-filtr-field">
            <div>
              <h2 className="report-heading"> Report View</h2>
            </div>
            <div className="delete-post-form">
              <Row className="post-view">
                <Col>
                  <div className="profile-disp post-view-margin-top">
                    <div className="form-set input-profile post-view-form">
                      <Form.Group
                        className="mb-3 input-field-width "
                        controlId="postViewFormName"
                      >
                        <Form.Label>Report By </Form.Label>
                        <Form.Control
                          type="text"
                          value={Reportdetails?.reportBy?.name || "N/A"}
                          readOnly
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="postViewFormReportedUser"
                      >
                        <Form.Label>Report to</Form.Label>
                        <Form.Control
                          type="text"
                          value={Reportdetails?.reportTo?.name || "N/A"}
                          readOnly
                        />
                      </Form.Group>
                    </div>
                    <div className="form-set input-profile mt-2">
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="postViewFormReportedUser"
                      >
                        <Form.Label>Reason</Form.Label>
                        <Form.Control type="text" value={Reportdetails?.reportTo?.name || "N/A"} readOnly />
                      </Form.Group>
                    </div>

                    <div className="form-set input-profile mt-2">
                      <Form.Group
                        className="mb-3 input-field-width post-view-input-width "
                        controlId="postViewFormDescription"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="Type here"
                          className="description-textarea"
                          value={Reportdetails?.desc || "N/A"}
                          readOnly
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
