import { createSlice } from "@reduxjs/toolkit";
import { getActivities } from "../Actions/AdminActions";


export const activitySlice = createSlice({
    name: "activitySlice",
    initialState: {
        activity: [],
        loading: false,
        error: null,
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(getActivities.pending, (state) => {
                state.loading = true; 
                state.error = null;  
            })
            .addCase(getActivities.fulfilled, (state, action) => {
                state.loading = false; 
                state.activity = action.payload.data; 
            })
            .addCase(getActivities.rejected, (state, action) => {
                state.loading = false; 
                state.error = action.error.message; 
            });
    },
});

export default activitySlice.reducer;
