import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import {
  Button,
  Container,
  Form,
  Modal,
  Pagination,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  DeleteNotification,
  NotificationListing,
} from "../Redux/Actions/AdminActions";
import SearchBar from "../Common/Searchbar";
import PaginationComponent from "../Components/Layout/Pagination";
import { all } from "axios";
import { toast } from "react-toastify";

export default function Notification() {
  const { alldata, loading, error, param } = useSelector((state) => state.noti);

  console.log(alldata);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);

  const [flag, setflag] = useState(true);
  const [userid, setUserid] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      NotificationListing({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: searchValue,
    };
    dispatch(NotificationListing(searchData));
  }, [page, limit, searchValue, flag, dispatch]);

  const handleDeleteUSer = () => {
    dispatch(DeleteNotification({ id: userid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };




  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div>
              <h2 className="report-heading"> Notification</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <SearchBar setSearchValue={setSearchValue} />
                    </Form.Group>
                  </Form>
                </div>
                <Link to="/create-notification">
                  <div className="create-new-btn">
                    <Button type="submit">Create New</Button>
                  </div>
                </Link>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn table-report">
                <thead>
                  <tr>
                    <th>S.NO.</th>
                    <th>TITLE</th>
                    <th>DESCRIPTION</th>
                    <th>SENT BY</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {alldata?.data?.notifications?.map((res, index) => {
                    const dynamicIndex = (page - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>
                          <p>{dynamicIndex}</p>
                        </td>
                        <td>
                          <p>{res?.title || "N/A"}</p>
                        </td>
                        <td>
                          <p>{res?.desc || "N/A"}</p>
                        </td>
                        <td>
                          <p>
                            {res?.send_to == 0
                              ? "Users"
                              : res?.send_to == 1
                              ? "Service providers"
                              : "All users"}
                          </p>
                        </td>
                        <td>
                          <div className="delete">
                            <p
                              onClick={() => {
                                setshowd(true);
                                setUserid(res?._id);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Delete
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    Showing {1 + (page - 1) * limit} -
                    {Math.min(page * limit, alldata?.data?.totalCount)} of{" "}
                    {alldata?.data?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponent
                    currentPage={page}
                    totalCount={alldata?.data?.totalCount}
                    limit={alldata?.data?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to Delete this Notification?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeleteUSer}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
