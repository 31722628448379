import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { getdashboard } from '../Redux/Actions/AdminActions';

function CommonDashBoard() {
  const { alldata }  = useSelector(
    (state) => state.dash
  );
  console.log(alldata);

  const dispatch = useDispatch();

  useEffect(()=> {
    dispatch(getdashboard())
  },[])

 

  return (
    <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap">
                <Row>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Total Users</p>
                          <h2>
                            {alldata?.totalUsers || "0"}
                          </h2>
                          {/* <span className="total-users">Session</span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/session-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Service Providers </p>
                          <h2>
                          {alldata?.serviceProviders || "0"}
                          </h2>
                          {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/padi-user.svg").default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Active Users</p>
                          <h2>
                          {alldata?.activeUsers || "0"}

                          </h2>
                          {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/active-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Pending Service Providers</p>
                          <h2>
                          {alldata?.pendingServiceProviders || "0"}

                          </h2>
                          {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/pending-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
    
  )
}

export default CommonDashBoard